var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex fill-height" },
    [
      _c("Navbar"),
      _c(
        "v-layout",
        { attrs: { row: "", "p-12": "", "justify-center": "" } },
        [
          _c("v-col", { attrs: { sm: "12", md: "8" } }, [
            _c(
              "div",
              { staticClass: "contact-wrapper" },
              [
                _c(
                  "v-layout",
                  { attrs: { row: "" } },
                  [
                    _c("v-col", {
                      attrs: { md: "4", sm: "12" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("default.page.contact.emailus")
                        )
                      }
                    }),
                    _c(
                      "v-col",
                      { attrs: { md: "8", sm: "12" } },
                      [
                        _vm.submitted
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "p-6 text-lg text-blue-700 bg-blue-100 border border-blue-500 rounded"
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("default.page.contact.success")
                                    )
                                  )
                                ])
                              ]
                            )
                          : _c(
                              "v-form",
                              {
                                ref: "form",
                                on: {
                                  submit: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.uploadContact($event)
                                  }
                                }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: _vm.$t("default.name"),
                                    type: "text",
                                    rules: _vm.nameRules,
                                    outlined: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "name", $$v)
                                    },
                                    expression: "form.name"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    label: _vm.$t("default.company"),
                                    type: "text",
                                    rules: _vm.companyNameRules,
                                    outlined: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.companyName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "companyName", $$v)
                                    },
                                    expression: "form.companyName"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    label: _vm.$t("default.email"),
                                    type: "email",
                                    rules: _vm.emailRules,
                                    outlined: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "email", $$v)
                                    },
                                    expression: "form.email"
                                  }
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    label: _vm.$t("default.phone"),
                                    type: "telephone",
                                    rules: _vm.phoneRules,
                                    outlined: "",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.phone,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "phone", $$v)
                                    },
                                    expression: "form.phone"
                                  }
                                }),
                                _c("vuetify-country-select", {
                                  attrs: {
                                    outlined: "",
                                    rules: _vm.countryRules,
                                    label: _vm.$t("default.country"),
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.country,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "country", $$v)
                                    },
                                    expression: "form.country"
                                  }
                                }),
                                _c("v-textarea", {
                                  attrs: {
                                    label: _vm.$t(
                                      "default.page.contact.message"
                                    ),
                                    outlined: "",
                                    "auto-grow": ""
                                  },
                                  model: {
                                    value: _vm.form.message,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "message", $$v)
                                    },
                                    expression: "form.message"
                                  }
                                }),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      large: "",
                                      type: "submit"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("default.submit")))]
                                )
                              ],
                              1
                            )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }