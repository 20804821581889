<template>
  <div class="d-flex fill-height">
    <Navbar />
    <v-layout row align-center p-12>
      <v-col cols="6">
        <h1
          class="mb-3 text-2xl font-extrabold tracking-tight text-gray-500 sm:text-5xl md:text-6xl"
          v-html="$t('default.page.home.title')"
        ></h1>
        <v-layout row align-center>
          <v-col cols="12">
            <h3
              :class="`h-3 mb-8 text-2xl tracking-tight font-extrabold text-gray-900`"
            >
              {{ $t("default.page.home.subtitle") }}
            </h3>
            <p
              class="mt-3 mb-6 text-base text-gray-500 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 md:text-l lg:mx-0"
            >
              {{ $t("default.page.home.description") }}
            </p>
            <router-link
              to="/contact"
              class="px-2 py-1 text-sm tracking-wider uppercase border rounded primary--text border-primary focus:bg-blue-50"
            >
              {{ $t("default.page.home.getstarted") }}
            </router-link>
          </v-col>
        </v-layout>
      </v-col>
      <v-col cols="6">
        <img src="../../../assets/images/content04.svg" alt="" />
      </v-col>
      <!-- </v-flex> -->
    </v-layout>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/Navbar.vue";
export default {
  name: "Home",
  components: { Navbar },
};
</script>
