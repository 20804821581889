<template>
  <v-app>
    <v-main class="white">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  methods: {
    ...mapActions(["initAction"])
  },
  mounted() {
    console.log("DEBUG: Inside default APP mounted()");
    this.initAction();
  }
};
</script>
