<template>
  <div class="d-flex fill-height">
    <Navbar />
    <v-layout row p-12 justify-center>
      <v-col sm="12" md="8">
        <div class="contact-wrapper">
          <v-layout row>
            <v-col
              md="4"
              sm="12"
              v-html="$t('default.page.contact.emailus')"
            ></v-col>
            <v-col md="8" sm="12">
              <div
                v-if="submitted"
                class="p-6 text-lg text-blue-700 bg-blue-100 border border-blue-500 rounded"
              >
                <p>{{ $t("default.page.contact.success") }}</p>
              </div>

              <v-form
                v-else
                ref="form"
                class=""
                v-on:submit.stop.prevent="uploadContact"
              >
                <v-text-field
                  :label="$t('default.name')"
                  type="text"
                  v-model="form.name"
                  :rules="nameRules"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  :label="$t('default.company')"
                  type="text"
                  v-model="form.companyName"
                  :rules="companyNameRules"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  :label="$t('default.email')"
                  type="email"
                  v-model="form.email"
                  :rules="emailRules"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  :label="$t('default.phone')"
                  type="telephone"
                  v-model="form.phone"
                  :rules="phoneRules"
                  outlined
                  required
                ></v-text-field>
                <vuetify-country-select
                  outlined
                  v-model="form.country"
                  :rules="countryRules"
                  :label="$t('default.country')"
                  required
                />
                <v-textarea
                  :label="$t('default.page.contact.message')"
                  v-model="form.message"
                  outlined
                  auto-grow
                ></v-textarea>
                <v-btn color="primary" large type="submit">{{
                  $t("default.submit")
                }}</v-btn>
              </v-form>
            </v-col>
          </v-layout>
        </div>
      </v-col>
    </v-layout>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import firebase from "firebase/app";

export default {
  name: "Contact",
  components: { Navbar },
  data() {
    return {
      form: {
        name: "",
        companyName: "",
        email: "",
        phone: "",
        country: "",
        message: "",
      },
      dataStore: null,
      submitted: false,
    };
  },
  mounted() {
    const db = firebase.firestore();
    const collection = db.collection("contact_form_messages");
    this.dataStore = collection;
  },
  computed: {
    nameRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("default.name") }),
        (v) =>
          v.length >= 3 ||
          this.$t("validation.string.length>=", {
            value: this.$t("default.name"),
            length: "3",
          }),
      ];
    },
    companyNameRules() {
      return [
        (v) => !!v || this.$t("validation.required", { value: "Company name" }),
      ];
    },
    countryRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("default.country") }),
      ];
    },
    phoneRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("default.phone") }),
      ];
    },
    emailRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("default.email") }),
        (v) => /.+@.+\..+/.test(v) || this.$t("validation.email.valid"),
      ];
    },
  },
  methods: {
    async uploadContact() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        const data = Object.assign({}, this.form);
        data.country = this.form.country.countryName;
        await this.dataStore.add(data);
        this.submitted = true;
      }
    },
  },
};
</script>
