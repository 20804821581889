<template>
  <nav>
    <v-app-bar flat app class="px-10 white lighten-3 py-7">
      <v-app-bar-nav-icon
        class="grey--text"
        v-if="getCurrentUser != null"
      ></v-app-bar-nav-icon>

      <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
        <a
          href="/"
          class="items-center text-uppercase black--text align-center"
        >
          <img
            class="w-auto h-8 mr-3 sm:h-10"
            src="../../../assets/images/logo-17.png"
            alt="dex-logo"
          />
        </a>
      </div>

      <v-spacer></v-spacer>
      <div class="space-x-3">
        <router-link
          v-if="hasSubdomain"
          to="/login"
          class="px-2 py-1 text-sm tracking-wider uppercase border rounded primary--text border-primary focus:bg-blue-50"
          >{{ $t("default.signin") }}</router-link
        >
        <router-link
          v-if="!hasSubdomain"
          to="/contact"
          class="px-2 py-1 text-sm tracking-wider uppercase border rounded primary--text border-primary focus:bg-blue-50"
          >{{ $t("default.contactus") }}</router-link
        >
      </div>
    </v-app-bar>
  </nav>
</template>

<script>
import firebase from "firebase/app";
export default {
  data() {
    return {
      getCurrentUser: firebase.auth().currentUser,
    };
  },
  methods: {},
  computed: {
    hasSubdomain() {
      const hosturl = window.location.host.split(".");
      return hosturl.length > 1;
    },
  },
};
</script>
