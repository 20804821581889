import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "../../plugins/vuetify";
import VuetifyCountryRegionSelect from "vuetify-country-region-select";
import i18n from '../../plugins/i18n';

Vue.use(VuetifyCountryRegionSelect);

const default_app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export default default_app;
