var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "px-10 white lighten-3 py-7",
          attrs: { flat: "", app: "" }
        },
        [
          _vm.getCurrentUser != null
            ? _c("v-app-bar-nav-icon", { staticClass: "grey--text" })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "flex items-center flex-grow flex-shrink-0 lg:flex-grow-0"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "items-center text-uppercase black--text align-center",
                  attrs: { href: "/" }
                },
                [
                  _c("img", {
                    staticClass: "w-auto h-8 mr-3 sm:h-10",
                    attrs: {
                      src: require("../../../assets/images/logo-17.png"),
                      alt: "dex-logo"
                    }
                  })
                ]
              )
            ]
          ),
          _c("v-spacer"),
          _c(
            "div",
            { staticClass: "space-x-3" },
            [
              _vm.hasSubdomain
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "px-2 py-1 text-sm tracking-wider uppercase border rounded primary--text border-primary focus:bg-blue-50",
                      attrs: { to: "/login" }
                    },
                    [_vm._v(_vm._s(_vm.$t("default.signin")))]
                  )
                : _vm._e(),
              !_vm.hasSubdomain
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "px-2 py-1 text-sm tracking-wider uppercase border rounded primary--text border-primary focus:bg-blue-50",
                      attrs: { to: "/contact" }
                    },
                    [_vm._v(_vm._s(_vm.$t("default.contactus")))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }