var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex fill-height" },
    [
      _c("Navbar"),
      _c(
        "v-layout",
        { attrs: { row: "", "align-center": "", "p-12": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("h1", {
                staticClass:
                  "mb-3 text-2xl font-extrabold tracking-tight text-gray-500 sm:text-5xl md:text-6xl",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("default.page.home.title"))
                }
              }),
              _c(
                "v-layout",
                { attrs: { row: "", "align-center": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "h3",
                        {
                          class:
                            "h-3 mb-8 text-2xl tracking-tight font-extrabold text-gray-900"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("default.page.home.subtitle")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "mt-3 mb-6 text-base text-gray-500 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 md:text-l lg:mx-0"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("default.page.home.description")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "px-2 py-1 text-sm tracking-wider uppercase border rounded primary--text border-primary focus:bg-blue-50",
                          attrs: { to: "/contact" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("default.page.home.getstarted")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/content04.svg"),
                alt: ""
              }
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }