<template>
  <div class="flex bg-gray-100 fill-height">
    <v-container flex fill-height>
      <v-layout row align-center>
        <v-col cols="12" md="6" offset-md="3">
          <div class="p-10 bg-white border rounded-md shadow">
            <v-card-title>{{ $t("default.login") }}</v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="login"
              >
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :label="$t('default.email')"
                  type="email"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="$t('default.password')"
                  type="password"
                  outlined
                  required
                ></v-text-field>
                <div v-if="error" class="mb-5 text-red-600 err" color="danger">
                  {{ error.message }}
                </div>
                <v-btn color="primary" large type="submit">{{
                  $t("default.login")
                }}</v-btn>
              </v-form>
            </v-card-text>
          </div>
        </v-col>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import * as firebase from "firebase/app";
import "firebase/auth";
// import mapGetters from "vuex";

export default {
  data: () => ({
    valid: true,
    tenantId: "",
    email: "",
    password: "",
    rememberMe: false,
    lazy: true,
    error: "",
  }),
  computed: {
    emailRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", { value: this.$t("default.email") }),
        (v) => /.+@.+\..+/.test(v) || this.$t("validation.email.valid"),
      ];
    },
    passwordRules() {
      return [
        (v) =>
          !!v ||
          this.$t("validation.required", {
            value: this.$t("default.password"),
          }),
        (v) =>
          (v && v.length >= 6) ||
          this.$t("validation.string.length>=", {
            value: this.$t("default.password"),
            length: "6",
          }),
      ];
    },
  },
  methods: {
    async login() {
      this.tenantId = this.$store.getters.getTenantID;
      console.log(`DEBUG: Tenant ID => ${this.tenantId}`);
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        try {
          if (this.tenantId != "admin") {
            firebase.auth().tenantId = this.tenantId;
          }
          const user = await firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password);
          this.$store.commit("setCurrentUser", user);
          this.$router.replace({ name: "Home" });
        } catch (error) {
          this.error = error;
        }
      }
    },
  },
};
</script>
