import Vue from "vue";
import Vuex from "vuex";

import firebase from "firebase/app";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tenantId: ""
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setTenantId(state, payload) {
      state.tenantId = payload;
    }
  },
  actions: {
    initAction({ dispatch }) {
      console.log("DEBUG: initAction ()");
      const isAuthenticated = firebase.auth().currentUser;
      dispatch("getTenantID");
      if (isAuthenticated) {
        dispatch("getUserClaims");
      }
    },
    async getTenantID({ commit }) {
      const hosturl = window.location.host;
      const subdomain = hosturl.split(".")[0];

      console.log(`DEBUG: getTenantID -> subdomain => ${subdomain}`);
      console.log(`DEBUG: getTenantID -> hosturl => ${hosturl}`);
      /*
       * TODO: Have a safer way of checking and limiting tenant names.
       * Check for for 'admin' early and act accordingly
       * Redirect for 'www' and other reserved or blocked tenant names
       * What character to allow or restrict in tenant names
       */

      if (subdomain == "admin") {
        commit("setTenantId", "admin");
      } else {
        console.log(`DEBUG: getTenantID -> subdomain !== admin`);
        const db = firebase.firestore();
        try {
          const snapshot = await db
            .collection("tenants")
            .where("subdomain", "==", subdomain)
            .get();

          if (snapshot.empty) {
            console.log(`DEBUG: getTenantID -> snapshot.empty`);
            commit("setTenantId", "admin");
            return;
          }

          let docs = []
          snapshot.forEach(doc => {
            docs.push(doc);
          });

          // console.log(docs);
          const data = docs[0].data();

          const tenant_id = data.tenant_id;
          console.log(`DEBUG. default/store. snapshot not empty. Tenant Id found ${tenant_id}`);
          commit("setTenantId", tenant_id);
        } catch (err) {
          // TODO: Rediect user to home page when tenant not found
          console.log('DEBUG: catch(err) Tenant Id NOT found');
          console.log(err);
          // console.log("A malfunction Occurred");
          // console.log(err);
          commit("setTenantId", "admin");
        }
      }

    }
  },
  modules: {},
  getters: {
    getTenantID: state => state.tenantId
  }
});
