var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex bg-gray-100 fill-height" },
    [
      _c(
        "v-container",
        { attrs: { flex: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", "align-center": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6", "offset-md": "3" } },
                [
                  _c(
                    "div",
                    { staticClass: "p-10 bg-white border rounded-md shadow" },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.$t("default.login")))
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.login($event)
                                }
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.emailRules,
                                  label: _vm.$t("default.email"),
                                  type: "email",
                                  outlined: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.passwordRules,
                                  label: _vm.$t("default.password"),
                                  type: "password",
                                  outlined: "",
                                  required: ""
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              }),
                              _vm.error
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mb-5 text-red-600 err",
                                      attrs: { color: "danger" }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.error.message) + " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    large: "",
                                    type: "submit"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("default.login")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }